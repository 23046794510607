import React, { useEffect, createContext, useState, useContext } from 'react';
import { useRouter } from 'next/router';

// Create a context for the search query
export const SearchContext = createContext({});

// Custom hook to access the search query and setter for landing page
export const useSearchContext = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  const router = useRouter();
  const initialSearchQuery = router.query.q || ''; // Initialize with router query if available
  const initialSelectedCategory =
    router.query.category ||
    (router.pathname === '/'
      ? 'All Tours'
      : router.pathname === '/things-to-do'
      ? 'All Attractions'
      : '');

  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );
  const [showFullMap, setShowFullMap] = useState(false);
  const [selectedAttraction, setSelectedAttraction] = useState();
  const [selectedTourCity, setSelectedTourCity] = useState('');
  const [dropdownAttractions, setDropdownAttractions] = useState([]);
  const [dropdownTours, setDropdownTours] = useState([]);
  const [dropdownCities, setDropdownCities] = useState([]);
  const [uniqueAttractionsThingsTodo, setUniqueAttractionsThingsTodo] =
    useState([]);
  const [landingPageCenterPoint, setLandingPageCenterPoint] = useState({
    lat: null,
    lng: null
  });

  useEffect(() => {
    if (router.query.q !== searchQuery) {
      setSearchQuery(router.query.q || '');
    }
    if (router.query.category !== selectedCategory) {
      setSelectedCategory(
        router.query.category ||
          selectedCategory ||
          (router.pathname === '/' ? 'All Tours' : 'All Attractions')
      );
    }
  }, [router.query.q, router.query.category]);

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        showFullMap,
        setShowFullMap,
        selectedAttraction,
        setSelectedAttraction,
        dropdownAttractions,
        setDropdownAttractions,
        uniqueAttractionsThingsTodo,
        setUniqueAttractionsThingsTodo,
        landingPageCenterPoint,
        setLandingPageCenterPoint,
        dropdownTours,
        setDropdownTours,
        dropdownCities,
        setDropdownCities,
        selectedTourCity,
        setSelectedTourCity,
        selectedCategory,
        setSelectedCategory
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
