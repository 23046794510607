import React from 'react';

interface IProps {
  color?: string;
  className?: string;
}

export const LoadingSpinner = ({ color, className }: IProps) => {
  return (
    <div
      className={`animate-spin  rounded-full border-t-4 border-primary border-solid ${
        className || 'h-10 w-10'
      }`}
    ></div>
  );
};
