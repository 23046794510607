import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import styled from 'styled-components';
import { useRouter } from 'next/router';

// Install Swiper modules
SwiperCore.use([Navigation]);

const StyledSwiper = styled(Swiper)`
  // Set the desired styles for the Swiper slider here
  width: 100%;
  .swiper-slide {
    width: auto !important;
  }
  .swiper-wrapper {
    align-items: center;
  }

  .swiper-pagination-bullet-active {
    background-color: #fd4c5c;
  }

  .swiper-pagination {
    margin-bottom: 15px;
  }
  .swiper-container-prev {
    width: 45px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: 0%;
    z-index: 10;
  }
  .swiper-container-next {
    top: 0%;
    position: absolute;
    right: 0%;
    background-color: #fff;
    width: 45px;
    height: 50px;
    z-index: 10;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border-radius: 50%;
    z-index: 10;

    &::after {
      color: white;
      position: absolute;
      width: 7px;
      height: 7px;
      top: 37%;
      left: 50%;
      font-size: 1rem;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-color: #fff;
      z-index: 50;
    }
  }

  .swiper-button-next {
    background-color: #f15c5a;
    right: 5px;
  }

  .swiper-button-prev {
    left: 5px;
    background-color: #f15c5a;
    &::after {
      left: 40%;
    }
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }
  .swiper-button-disabled {
    display: none;
  }
`;

interface BreadcrumbSliderProps {
  breadcrumbs: string[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const BreadcrumbSlider: React.FC<BreadcrumbSliderProps> = ({
  breadcrumbs,
  activeIndex,
  setActiveIndex
}) => {
  // Function to handle click on related url tag
  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  const router = useRouter();
  const { slug } = router.query;

  return (
    <div className="relative">
      <StyledSwiper
        className="mySwiper border-b border-[#C5C5C5]"
        slidesPerView={2}
        grabCursor
        breakpoints={{
          320: {
            slidesPerView: slug && slug.length > 15 ? 1 : 1.4
          },
          375: {
            slidesPerView: slug && slug.length > 15 ? 1.15 : 1.5
          },
          440: {
            slidesPerView: slug && slug.length > 15 ? 1.4 : 1.6
          },
          500: {
            slidesPerView: slug && slug.length > 15 ? 1.4 : 1.8
          },
          550: {
            slidesPerView: 1.6
          },
          600: {
            slidesPerView: 1.7
          },
          650: {
            slidesPerView: 1.9
          },
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 2.5
          },
          1180: {
            slidesPerView: 3
          },
          1300: {
            slidesPerView: 4
          }
        }}
        navigation={{
          nextEl: '.swiper-container-next',
          prevEl: '.swiper-container-prev'
        }}
      >
        {breadcrumbs.map((breadcrumb, index) => (
          <SwiperSlide key={index}>
            <div className="flex items-center mb-1">
              <h1
                onClick={() => handleClick(index)}
                className={`xxsm:text-base md:text-lg font-semibold leading-relaxed flex cursor-pointer mt-0 ${
                  index === activeIndex ? 'text-primary' : ''
                } `}
              >
                {breadcrumb}
              </h1>
              <p className={`text-[20px] font-medium mx-2.5 `}>
                {index !== breadcrumbs.length - 1 && breadcrumbs[index + 1]
                  ? '|'
                  : ''}
              </p>
            </div>
          </SwiperSlide>
        ))}
        <div className="hidden lg:flex swiper-container-prev">
          <div className="swiper-button-prev"></div>
        </div>
        <div className="hidden lg:flex swiper-container-next">
          <div className="swiper-button-next"></div>
        </div>
      </StyledSwiper>
    </div>
  );
};

export default BreadcrumbSlider;
