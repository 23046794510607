import Image from 'next/image';
import React, { useState } from 'react';
import {
  AttractionCardProps,
  EAttractionCardPageType
} from '../../types/components/attraction-card';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  optimizeImageUrl,
  optimizeVideoUrl
} from '../../utils/videoLinkOptimize';
import { styled } from 'styled-components';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSearchContext } from '../../utils/context/searchContext';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const StyledSwiper = styled(Swiper)`
  // Set the desired styles for the Swiper slider here
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  .swiper-pagination {
    margin-bottom: 15px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border-radius: 50%;
    opacity: 0.5;
    z-index: 10;

    &::after {
      color: rgba(170, 170, 170, 1);
      position: absolute;
      width: 7px;
      height: 7px;
      top: 42%;
      left: 55%;
      font-size: 0.75rem;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-color: #fff;
      z-index: 50;
    }
  }

  .swiper-button-next {
    background-color: #fff;
    opacity: 1;
    right: 5px;
  }

  .swiper-button-prev {
    left: 5px;
    background-color: #fff;
    opacity: 1;
    &::after {
      left: 40%;
    }
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }
  .swiper-button-disabled {
    display: none;
  }
`;

const AttractionCard = React.forwardRef<HTMLDivElement, AttractionCardProps>(
  ({ attractionCard, isMapCard, pageType }, ref) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const { showFullMap }: any = useSearchContext();

    const handleMouseEnter = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
    };
    const handleFavoriteAttraction = () => {
      setIsFavorite(!isFavorite);
    };

    const isLandingPage = pageType == EAttractionCardPageType.LANDING_PAGE;

    return (
      <>
        <Link href={`/${attractionCard.slug}`}>
          <div
            className={`w-full ${
              isMapCard && `bg-white p-2`
            } rounded-[18px] cursor-pointer relative ${
              isLandingPage && !showFullMap
                ? `h-[370px]`
                : pageType == EAttractionCardPageType.THINGS_TODO_PAGE
                ? `h-[365px]`
                : `h-[300px]`
            }`}
            key={attractionCard.id}
            ref={ref}
          >
            {/* <Image
            width={34}
            height={34}
            src={isFavorite ? '/redHeartIcon.svg' : '/grayHeartIcon.svg'}
            alt={'heart-icon'}
            className="absolute top-4 right-4"
            onClick={handleFavoriteAttraction}
          /> */}

            {isLandingPage ? (
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="relative"
              >
                <StyledSwiper
                  slidesPerView={1}
                  spaceBetween={1}
                  navigation={
                    isHovering
                      ? {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev'
                        }
                      : false
                  }
                  pagination={
                    isHovering
                      ? {
                          dynamicBullets: false,
                          clickable: true
                        }
                      : false
                  }
                  className={`${
                    isLandingPage && showFullMap ? `h-[170px]` : `h-[215px]`
                  }  w-full rounded-xl`}
                  lazy
                >
                  {attractionCard.carousel?.map((img: any, index: number) => (
                    <SwiperSlide key={index} className="w-[100%] h-[100%]">
                      {img?.hasVideo ? (
                        <video
                          src={optimizeVideoUrl(img?.imageUrl)}
                          muted
                          loop
                          playsInline
                          className={`${
                            isLandingPage && showFullMap
                              ? `h-[170px]`
                              : `h-[215px]`
                          }  w-full rounded-xl object-cover`}
                          autoPlay
                          preload="true"
                        ></video>
                      ) : (
                        <Image
                          src={optimizeImageUrl(img?.imageUrl)}
                          width={350}
                          height={350}
                          alt="feature product image"
                          className={`${
                            isLandingPage && showFullMap
                              ? `h-[170px]`
                              : `h-[215px]`
                          }  w-full rounded-xl object-cover hover:scale-105 ease-in-out duration-200`}
                          placeholder="blur"
                          blurDataURL="/placeholder.webp"
                          priority
                        />
                      )}
                    </SwiperSlide>
                  ))}
                  {isHovering && (
                    <>
                      <div className="swiper-button-next"></div>
                      <div className="swiper-button-prev"></div>
                    </>
                  )}
                </StyledSwiper>
              </div>
            ) : (
              <Image
                width={300}
                height={300}
                src={optimizeImageUrl(attractionCard.image)}
                alt={attractionCard.name}
                className={`${
                  isLandingPage && showFullMap ? `h-[170px]` : `h-[215px]`
                }  w-full rounded-xl`}
                priority
              />
            )}
            <div
              className={`flex flex-col gap-2.5 ${
                isMapCard ? `py-3 px-2` : `pt-[15px] pr-2`
              } `}
            >
              <div className="flex h-[19px]">
                <h2 className="text-neutral-900 text-base font-semibold leading-[17px] overflow-hidden truncate w-[80%]">
                  {attractionCard.name}
                </h2>
              </div>
              <div
                className={`text-gray4F text-sm font-normal leading-5 h-[40px] overflow-hidden`}
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {attractionCard.description}
              </div>

              <div className="flex items-center justify-between gap-2 w-full overflow-hidden">
                <div className="flex gap-1 items-center">
                  {attractionCard?.openingHours && (
                    <span className="flex text-black13 text-sm font-normal text-center items-center">
                      <Image
                        src={'/gray-clock-icon.svg'}
                        alt="gray-clock"
                        width={13}
                        height={13}
                      />
                    </span>
                  )}

                  <span className="text-[#777] leading-4 text-sm font-normal">
                    {attractionCard?.openingHours?.toLocaleLowerCase() ?? ''}
                  </span>
                </div>
                {attractionCard?.rating !== undefined &&
                  attractionCard?.ratingCount !== undefined && (
                    <p className="flex gap-[2px] items-center">
                      <Image
                        src={'/homestar.svg'}
                        width={14}
                        height={14}
                        alt="rating-star"
                        className="mb-[2px]"
                      />
                      <span className="text-sm font-normal leading-4 text-black13">
                        {attractionCard?.rating?.toFixed(1) || '0.0'}
                      </span>
                      <span className="text-[#777] text-xs font-normal leading-4">
                        ({attractionCard?.ratingCount || 0})
                      </span>
                    </p>
                  )}
              </div>
              {attractionCard.price && !isMapCard && (
                <div className={`flex items-center justify-between w-full`}>
                  <div className="flex gap-2 items-center w-full justify-between">
                    <div>
                      <span className="text-neutral-900 text-sm font-normal">
                        from&nbsp;
                      </span>
                      <span className="text-neutral-900 text-lg font-semibold">
                        ${attractionCard.price}
                      </span>
                    </div>
                    {/* <button className="border-rose-500 text-rose-500 border flex items-center h-[35px] px-2.5 py-[7px] rounded-[48px] text-sm font-normal ">
                    Buy Ticket
                  </button> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Link>
      </>
    );
  }
);
AttractionCard.displayName = 'AttractionCard';
export default AttractionCard;
