import { Dispatch, SetStateAction } from 'react';
import { ThingsToDO } from '../pages/things-to-do';
import { TopTip } from '../../components/Attraction/topTip';

export type AttractionCarouselType = {
  key: number;
  imageUrl: string;
  hasVideo: boolean;
};

export type AttractionCardType = {
  id: string;
  name: string;
  coordinates: {
    lng: number;
    // viewport: {
    //   southwest: {
    //     lng: number;
    //     lat: number;
    //   };
    //   northeast: {
    //     lng: number;
    //     lat: number;
    //   };
    // };
    lat: number;
  };
  city?: string;
  country?: string;
  slug: string;
  description: string;
  ratingCount: number;
  rating: number;
  image: string;
  price?: boolean;
  openingHours?: string;
  carousel?: AttractionCarouselType[];
  attractionStatusTag?: string;
};

export enum EAttractionCardPageType {
  LANDING_PAGE = 'landing-page',
  THINGS_TODO_PAGE = 'things-todo-page'
}

export interface AttractionCardProps {
  attractionCard: AttractionCardType;
  index: number;
  isMapCard?: boolean;
  pageType: EAttractionCardPageType;
}

export interface Center {
  lat: number | null;
  lng: number | null;
}

export interface ThingsTodoMobileProps {
  attractionData: AttractionCardType[] | undefined;
  setAttractionData: Dispatch<SetStateAction<AttractionCardType[] | undefined>>;
  thingsToDoDetails: ThingsToDO;
  showFullMap: boolean;
  topTips: TopTip[];
  radius: number;
  setRadius: Dispatch<SetStateAction<number>>;
  mapRef: any;
  paginatedAttractionData: AttractionCardType[];
  currentPage: number;
  attractionsPerPage: number;
  handlePageChange: (page: number) => void;
}

export enum ESectionInView {
  LIST = 'list',
  MAP = 'map'
}
