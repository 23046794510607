import React, { createContext, useState, useEffect } from 'react';

export const PaxContext = createContext(null);

const getStoredData = (key, defaultValue) => {
  if (typeof window !== 'undefined') {
    const storedData = localStorage.getItem(key);
    try {
      return storedData ? JSON.parse(storedData) : defaultValue;
    } catch (error) {
      console.error(`Error parsing localStorage key "${key}":`, error);
      return defaultValue;
    }
  }
  return defaultValue;
};

export const PaxProvider = ({ children }) => {
  const [pax, setPax] = useState(() => getStoredData('pax', {}));
  const [tour, setTour] = useState(() => getStoredData('tour', {}));
  const [user, setUser] = useState(() => getStoredData('user', {}));
  const [blogCity, setBlogCity] = useState(() =>
    getStoredData('blogCity', null)
  );
  const [bestReview, setBestReview] = useState(() =>
    getStoredData('bestReview', '')
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('pax', JSON.stringify(pax));
      localStorage.setItem('tour', JSON.stringify(tour));
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('blogCity', JSON.stringify(blogCity));
      localStorage.setItem('bestReview', JSON.stringify(bestReview));
    }
  }, [pax, tour, user, blogCity]);

  const updatePax = newObject1 => {
    setPax(newObject1);
  };

  const updateTour = newObject2 => {
    setTour(newObject2);
  };

  const updateUser = newObject3 => {
    setUser(newObject3);
  };
  const updateBestReview = newObject5 => {
    setBestReview(newObject5);
  };
  const updateBlogCity = newObject4 => {
    setBlogCity(newObject4);
  };

  const contextValue = {
    pax,
    tour,
    user,
    blogCity,
    bestReview,
    updatePax,
    updateBestReview,
    updateTour,
    updateUser,
    updateBlogCity
  };

  return (
    <PaxContext.Provider value={contextValue}>{children}</PaxContext.Provider>
  );
};
