import { useEffect, useRef } from 'react';

interface DebounceFunction<T extends any[]> {
  (...args: T): void;
  cancel: () => void;
}

export function useDebounce<T extends any[]>(
  func: (...args: T) => void,
  delay: number
): DebounceFunction<T> {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = (...args: T) => {
    clearTimeout(timeoutRef.current as NodeJS.Timeout);
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };

  debouncedFunction.cancel = () => {
    clearTimeout(timeoutRef.current as NodeJS.Timeout);
    timeoutRef.current = null;
  };

  useEffect(() => {
    return () => {
      debouncedFunction.cancel();
    };
  }, []);

  return debouncedFunction;
}
