import { SetStateAction, Dispatch } from 'react';
import LandingPageTourCard from './LandingPageTourCard';
import { TourCardType } from '../../types/pages/landing-page';
import { LoadingSpinner } from '../../shared-components/LoadingSpinner/LoadingSpinner';
import Image from 'next/image';
import { useInstantSearch } from 'react-instantsearch';

interface IProps {
  hits: TourCardType[];
  totalPages: number;
  handleLoadMore: () => void;
  isLoading: boolean;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}
const LandingPageTours = ({
  hits,
  totalPages,
  handleLoadMore,
  isLoading,
  currentPage,
  setCurrentPage
}: IProps) => {
  // Infinite scroll commented for tour cards
  // const observer = useRef<IntersectionObserver>();
  // const isMobileScreen = useMediaQuery(640);

  // const lastTourElementRef = useCallback(
  //   (node: HTMLElement | null) => {
  //     if (isLoading || !node || isMobileScreen) return;

  //     if (observer.current) observer.current.disconnect();

  //     observer.current = new IntersectionObserver(entries => {
  //       if (entries[0].isIntersecting && currentPage < totalPages) {
  //         handleLoadMore();
  //       }
  //     });

  //     observer.current.observe(node);
  //   },
  //   [isLoading, currentPage, totalPages, handleLoadMore]
  // );

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  // };

  const { status } = useInstantSearch();

  return (
    <div
      id="tour_cards"
      className={`w-[100%] md:w-[90%] xl:w-[93%] 2xl:w-[90%] mx-auto mt-8 scroll-mt-52 min-h-screen ${
        currentPage < totalPages && `mb-[8rem]`
      }`}
    >
      {hits.length == 0 && (status == 'stalled' || status == 'loading') ? (
        <div className="flex items-center justify-center h-[550px] flex-col gap-10">
          <LoadingSpinner className="w-8 h-8" />
        </div>
      ) : hits.length === 0 ? (
        <div className="flex items-center justify-center h-[550px] flex-col gap-10">
          <Image
            src="https://res.cloudinary.com/see-sight-tours/image/upload/v1709532363/Remaining%20images/Attraction/ekhp3i11sp3dsr7e2poi.png"
            alt="no-attractions-image"
            width={300}
            height={300}
          />
          <p className="text-base font-normal">Currently no Tours available</p>
        </div>
      ) : (
        <div
          className={`grid grid-cols-1 mmsm:grid-cols-2 md:grid-cols-2 mmd:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-[18px]`}
        >
          {hits.map((cardDetails, index) => (
            <LandingPageTourCard
              tourCard={cardDetails}
              key={cardDetails.objectID}
              index={index + 1}
              // ref={index === hits.length - 1 ? lastTourElementRef : null}
            />
          ))}
        </div>
      )}
      {/* {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={page => handlePageChange(page)}
        />
      )} */}

      {hits.length > 0 && (
        <div className="flex justify-center py-10">
          {currentPage < totalPages && (
            <button
              className="bg-primary text-white rounded-full px-6 py-2"
              onClick={handleLoadMore}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center">
                  <LoadingSpinner className="w-4 h-4 mr-2 inline-block border-white" />
                  <p>Loading...</p>
                </div>
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>
      )}

      {/* {isLoading && currentPage < totalPages && (
        <div className="flex justify-center py-10">
          <LoadingSpinner className="w-8 h-8" />
        </div>
      )} */}
    </div>
  );
};

export default LandingPageTours;
