import React, { useEffect, useState, useMemo } from 'react';
import BreadcrumbSlider from './BreadcrumbSlider/BreadcrumbSlider';
import Link from 'next/link';
import { RelatedUrl } from '../../../types/pages/things-to-do';
import { ELandingPage } from '../../../types/pages/landing-page';

interface RelatedBreadcrumbs {
  breadcrumbs: RelatedUrl[];
  pagetype?: string;
}

export const RelatedBreadcrumbLinks = ({
  breadcrumbs,
  pagetype
}: RelatedBreadcrumbs) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAllBreadcrumbs, setShowAllBreadcrumbs] = useState(false);

  // Memoized to avoid unnecessary re-renders
  const sliderBreadcrumbs = useMemo(
    () => breadcrumbs.map(link => link.parent),
    [breadcrumbs]
  );
  const currentBreadcrumbLinks = useMemo(
    () => breadcrumbs[activeIndex]?.links || [],
    [breadcrumbs, activeIndex]
  );

  const handleSeeMoreClick = () => {
    setShowAllBreadcrumbs(true);
  };

  const handleSeeLessClick = () => {
    setShowAllBreadcrumbs(false);
  };
  const showbreadcrumblinks = pagetype == ELandingPage.TOURS_PAGE ? 50 : 35;
  return (
    <div
      className={`${
        pagetype == ELandingPage.TOURS_PAGE
          ? `w-[80%] mx-auto`
          : `w-[90%] md:w-[85%] xl:w-[80%] 3xl:w-[70%] mx-auto flex flex-col`
      }`}
    >
      <BreadcrumbSlider
        breadcrumbs={sliderBreadcrumbs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <div className="mb-[15px] mt-[10px]"></div>
      <div className="flex flex-col">
        {currentBreadcrumbLinks.length === 0 ? (
          <p className="text-gray-500 mb-4 text-start">
            No related links available.
          </p>
        ) : (
          <div className="flex flex-wrap">
            {currentBreadcrumbLinks
              .slice(
                0,
                showAllBreadcrumbs
                  ? currentBreadcrumbLinks.length
                  : showbreadcrumblinks
              )
              .map((breadcrumb, index) => (
                <div key={index} className="md:mb-1.5 xl:mb-2">
                  <Link
                    className="text-black33 text-[14px] md:text-[16px] font-normal leading-[35px] hover:text-darkBlue hover:underline"
                    href={`/${breadcrumb.url}`}
                    target="_blank"
                  >
                    {breadcrumb.name}
                  </Link>
                  {(index !== showbreadcrumblinks - 1 || showAllBreadcrumbs) &&
                    index !== currentBreadcrumbLinks.length - 1 && (
                      <span className="mx-2.5 text-[#757575] opacity-70">
                        |
                      </span>
                    )}
                </div>
              ))}
          </div>
        )}
        {currentBreadcrumbLinks.length > showbreadcrumblinks && (
          <button
            onClick={
              showAllBreadcrumbs ? handleSeeLessClick : handleSeeMoreClick
            }
            className="text-black13 text-base font-semibold leading-[35px] hover:text-darkBlue hover:underline text-right"
          >
            {showAllBreadcrumbs ? 'See less' : 'See more'}
          </button>
        )}
      </div>
    </div>
  );
};
