export const toTitleCase = (str: string) => {
  return str?.toLowerCase().replace(/(^|\s)\S/g, char => char.toUpperCase());
};

export const removeDuplicateCountryName = (city: string, country: string) => {
  if (city === 'Niagara Falls, Canada' || city === 'Niagara Falls, USA') {
    return city;
  }

  if (city && country) {
    return `${city}, ${
      country !== 'US' && country !== 'USA' ? toTitleCase(country) : country
    }`;
  } else if (country) {
    return `${
      country !== 'US' && country !== 'USA' ? toTitleCase(country) : country
    }`;
  } else {
    return city;
  }
};
