const getCurrentDayIndex = () => {
  const currentDate = new Date();
  let currentDayIndex = currentDate.getDay();
  currentDayIndex = (currentDayIndex + 6) % 7;

  return currentDayIndex;
};
export const getOpeningHoursForCurrentDay = (
  openingHours: string[] | undefined
): string => {
  if (!openingHours || openingHours.length === 0) {
    return '';
  }

  const currentDayIndex = getCurrentDayIndex();

  if (currentDayIndex < 0 || currentDayIndex >= openingHours.length) {
    return '';
  }

  const openingHoursForCurrentDay = openingHours[currentDayIndex];
  const extractedHours = openingHoursForCurrentDay.replace(/^[^:]+:\s*/, '');
  return extractedHours;
};

export const getDurationHoursFromMinutes = (duration: number) => {
  const minutesInDay = 1440;
  if (duration >= minutesInDay) {
    const days = duration / minutesInDay;
    return Number.isInteger(days) ? `${days} Days` : `${days.toFixed(1)} Days`;
  } else {
    const hours = duration / 60;
    return Number.isInteger(hours)
      ? `${hours} Hours`
      : `${hours.toFixed(1)} Hours`;
  }
};
