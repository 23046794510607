import Image from 'next/image';

import { useRouter } from 'next/router';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StickyFooterBar } from './StickyFooterBar';
import { SocialMediaIconLinks } from './SocialMediaIconLinks';
import { SocialMediaIcons } from '../../shared-components/SocialMediaIcons';
import { useMediaQuery } from '../../hooks/mediaQuery';
import { toast } from 'react-toastify';
const Link = dynamic(() => import('next/link'));
interface Props {
  isactive: string;
}

interface LinkItem {
  href: string;
  label: string;
}

interface FooterColumnProps {
  title: string;
  links: LinkItem[];
  id: string;
  setOpenQuickLinks: Dispatch<SetStateAction<boolean>>;
}

interface ContactInfoItem {
  imageSrc: string;
  title: string;
  description: string | React.ReactNode;
  onClick?: (number: string) => void;
}

const sections = [
  {
    id: 'Quick_Links',
    title: 'Quick Links',
    links: [
      { href: '/cities', label: 'Our Cities' },
      { href: '/guides', label: 'Our Guides' },
      { href: '/reviews', label: 'Reviews' },
      { href: '/faq', label: 'FAQs' },
      { href: '/contact', label: 'Contact Us' }
    ]
  },
  {
    id: 'book_your_tour',
    title: 'Book your Tour',
    links: [
      { href: '/tours', label: 'Tours' },
      { href: '/private-tours', label: 'Private Tours' }
    ]
  },
  {
    id: 'menu',
    title: 'Menu',
    links: [
      { href: '/blog', label: 'Blogs' },
      { href: '/about', label: 'About Us' },
      { href: '/careers', label: 'Careers' },
      { href: '/partner', label: 'Become a partner' }
    ]
  }
];

export const LinkStyle = styled.div<Props>`
  color: ${props => (props.isactive == 'true' ? 'red' : 'inherit')};
`;

const FooterColumn = ({
  title,
  links,
  id,
  setOpenQuickLinks
}: FooterColumnProps) => {
  const router = useRouter();
  return (
    <div className="" id={id}>
      <h2 className="text-[14px] md:text-[16px] font-[600]">{title}</h2>
      <ul className="pt-2 text-[#666]">
        {links.map((link: any) => (
          <li
            key={link.href}
            className="pb-2 lg:pt-2 text-[12px] md:text-[15px] font-[400]"
          >
            <Link href={link.href} onClick={() => setOpenQuickLinks(false)}>
              <LinkStyle isactive={(router.asPath === link.href).toString()}>
                {link.label}
              </LinkStyle>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ContactInfoList = () => {
  const isMobile = useMediaQuery(500);

  const handleCallClick = (number: string) => {
    if (isMobile) {
      // Mobile behavior: initiate a call
      window.location.href = `tel:${number}`;
    } else {
      // Web behavior: copy to clipboard and show toast
      navigator.clipboard
        .writeText(number)
        .then(() => {
          toast.success('Copied');
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Copied');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const contactInfoItems: ContactInfoItem[] = [
    {
      imageSrc:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713255721/lbmgp0ronijeyov5zrnf.svg',
      title: 'Toll Free',
      description: (
        <p
          onClick={() => handleCallClick('1-888-961-6584')}
          className="text-[#4285F4] cursor-pointer text-[13px] md:text-[15px]"
        >
          1-888-961-6584
        </p>
      )
    },
    {
      imageSrc:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713255721/lbmgp0ronijeyov5zrnf.svg',
      title: 'Local',
      description: (
        <p
          onClick={() => handleCallClick('1-289-271-9767')}
          className="text-[#4285F4] cursor-pointer text-[13px] md:text-[15px]"
        >
          1-289-271-9767
        </p>
      )
    },
    {
      imageSrc:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713255721/lgdstiwuzgvr59yrcspl.svg',
      title: 'Fax',
      description: (
        <p
          onClick={() => handleCopyText('1-888-908-6056')}
          className="text-[#4285F4] cursor-pointer text-[13px] md:text-[15px]"
        >
          1-888-908-6056
        </p>
      )
    },
    {
      imageSrc:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713269449/y2qgiehwuqexiapllgga.svg',
      title: 'Mail',
      description: (
        <a
          href="mailto:info@tripshepherd.com"
          className="text-[13px] md:text-[15px] text-[#4285F4]"
        >
          info@tripshepherd.com
        </a>
      )
    }
  ];

  return (
    <ul className="pt-2 mmd:flex mmd:flex-row lg:flex-col mmd:justify-between mmd:w-full lg:w-fit sm:grid sm:grid-rows-2 sm:grid-cols-2">
      {contactInfoItems.map((item, index) => (
        <li
          key={index}
          className="pb-2 lg:pt-2 text-[12px] md:text-[15px] font-[400] flex gap-5 items-center cursor-pointer"
        >
          <div>
            <Image
              src={item.imageSrc}
              width={38}
              height={38}
              alt=""
              className="max-w-[38px]"
            />
          </div>
          <div className="flex flex-col">
            <p>{item.title}</p>
            {item.description}
          </div>
        </li>
      ))}
    </ul>
  );
};

const Footer = () => {
  const router = useRouter();
  const [openQuickLinks, setOpenQuickLinks] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const toggleQuickLink = () => {
    setOpenQuickLinks(!openQuickLinks);
  };
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth < 640);
    };
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // For Disabling scroll When sticky footer is opened
  useEffect(() => {
    if (openQuickLinks && !isMobileScreen && !showNormalFooter) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openQuickLinks]);

  // Show Sticky Footer only on Tours(/) and Things to do page(/things-to-do)
  const showStickyFooter =
    (openQuickLinks || isMobileScreen) &&
    (router.route == '/' || router.route.startsWith('/things-to-do'));
  const showNormalFooter =
    router.route !== '/' && !router.route.startsWith('/things-to-do');

  return (
    <>
      {showStickyFooter || showNormalFooter ? (
        <div
          className={` ${
            isMobileScreen || showNormalFooter
              ? ''
              : ' bg-black bg-opacity-50 min-h-screen z-0 sm:z-[1050] flex justify-center items-center xl:items-start py-12 fixed inset-0'
          }  `}
          onClick={() => {
            if (showStickyFooter) {
              toggleQuickLink();
            }
          }}
        >
          <section
            id="footer_section"
            className={`relative pt-[60px] ${
              showNormalFooter ? `sm:pt-[60px]` : `sm:pt-[30px] sm:fixed`
            } bg-[#FAFAFA] px-[6%] xl:px-[8%] 2xl:px-[10%] overflow-hidden bottom-[85px] sm:bottom-0 z-[39] sm:z-[40] sm:w-full ${
              router.route == '/profile'
                ? `mt-0`
                : router.route.startsWith('/things-to-do')
                ? `mt-[85px]`
                : `mt-[130px]`
            } sm:mt-0`}
          >
            <div className="relative px-1 pb-4">
              {!isMobileScreen && !showNormalFooter && (
                <div className="flex justify-end cursor-pointer w-full">
                  <Image
                    src="/cross-icon.svg"
                    alt="cross icon"
                    width={35}
                    height={35}
                    onClick={toggleQuickLink}
                  />
                </div>
              )}
              <div
                className={`flex flex-col xl:flex-row gap-[50px] sm:gap-[25px] xl:gap-[70px] 2xl:gap-[100px] 3xl:gap-[150px] pb-[20px] ${
                  showNormalFooter ? `sm:pb-[30px]` : `sm:pb-[20px]`
                }`}
              >
                <div className="flex flex-col gap-[30px] md:gap-[20px]">
                  <div className="w-auto">
                    <div id="image-wrapper">
                      <Image
                        src="https://res.cloudinary.com/see-sight-tours/image/upload/v1713248433/f0jog4etqtzuxkep1kp5.svg"
                        width={250}
                        height={250}
                        className="max-h-[40px] w-[135px] md:w-[235px]"
                        alt="Tripshepherd logo image"
                      />
                    </div>
                    <div
                      id="intro-paragraph "
                      className="xl:max-w-[350px] sm:hidden xl:flex 4xl:max-w-[380px] pt-2 text-[12px] md:text-[14px] text-[#666] font-[400] text-justify"
                    >
                      Tripshepherd is transforming global travel by offering
                      online planning and booking for tours in top cities and
                      partnerships with major attractions, enhancing and
                      inspiring journeys worldwide.
                    </div>
                    <div className="mt-[20px] hidden xl:flex">
                      <SocialMediaIcons />
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-nowrap flex-wrap gap-[20px] msm:gap-[40px] sm:gap-[20px] lg:gap-[70px] xl:gap-[25px] mxl:gap-[50px] 3xl:gap-[100px] 4xl:gap-[200px]">
                  <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-[25px] xsm:gap-[40px] md:gap-[130px] lg:gap-[70px] xl:gap-[25px] mxl:gap-[50px] 3xl:gap-[100px] 4xl:gap-[200px]">
                    {sections.map(section => (
                      <FooterColumn
                        key={section.title}
                        title={section.title}
                        links={section.links}
                        id={section.id}
                        setOpenQuickLinks={setOpenQuickLinks}
                      />
                    ))}
                  </div>
                  <div className="sm:w-full lg:w-fit">
                    <div id="get_in_touch" className="">
                      <h2 className="text-[14px] md:text-[16px] font-[600]">
                        Get in Touch
                      </h2>
                      <ContactInfoList />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:hidden flex flex-col items-center justify-center mb-[20px] sm:mb-[15px] xl:mb-[20px]">
              <SocialMediaIconLinks />
              <div className="flex gap-5 mt-5">
                {['/privacy-policy', '/terms-and-conditions'].map(
                  (path, index) => (
                    <Link key={index} href={path}>
                      <LinkStyle
                        className="text-black hover:text-blue-400 hover:underline text-xs mmsm:text-[13px]"
                        isactive={(router.asPath === path).toString()}
                      >
                        {path === '/privacy-policy'
                          ? 'Privacy Policy'
                          : 'Terms and Conditions'}
                      </LinkStyle>
                    </Link>
                  )
                )}
              </div>
            </div>
            {(isMobileScreen || showNormalFooter) && (
              <StickyFooterBar
                isMobileScreen={isMobileScreen}
                toggleQuickLink={toggleQuickLink}
                openQuickLinks={openQuickLinks}
                showNormalFooter={showNormalFooter}
              />
            )}
          </section>
        </div>
      ) : (
        <section
          id="footer_section"
          className={`relative bg-[#FAFAFA] sm:fixed sm:bottom-0 z-[40] w-full border-t-1 border-[#E6E6E6] ${
            router.route == '/profile' ? `mt-0` : `mt-[130px]`
          } sm:mt-0`}
        >
          <StickyFooterBar
            isMobileScreen={isMobileScreen}
            toggleQuickLink={toggleQuickLink}
            openQuickLinks={openQuickLinks}
            showNormalFooter={showNormalFooter}
          />
        </section>
      )}
    </>
  );
};

export default Footer;
