import React from 'react';

interface Props {
  color: 'red' | 'gray';
}

const CitiesIcon: React.FC<Props> = ({ color }) => {
  const iconColor = color === 'red' ? '#F15C5A' : '#717171';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M13.75 20.6663H2.75V5.08301C2.75 2.80784 3.22483 2.33301 5.5 2.33301H11C13.2752 2.33301 13.75 2.80784 13.75 5.08301V20.6663ZM13.75 20.6663V7.83301H16.5C18.7752 7.83301 19.25 8.30784 19.25 10.583V20.6663H13.75ZM7.33333 5.99967H9.16667M7.33333 8.74967H9.16667M7.33333 11.4997H9.16667"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5443 20.666V16.9993C10.5443 16.1349 10.5443 15.7032 10.2757 15.4346C10.0071 15.166 9.57535 15.166 8.71094 15.166H7.79427C6.92985 15.166 6.4981 15.166 6.22952 15.4346C5.96094 15.7032 5.96094 16.1349 5.96094 16.9993V20.666"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CitiesIcon;
