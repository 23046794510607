import { useRouter } from 'next/router';
import React, { useEffect, useState, useContext } from 'react';
import ThingsToDoIcon from '../../../assets/icons/ThingsToDoIcon';
import ToursIcon from '../../../assets/icons/ToursIcon';
import ProfileIcon from '../../../assets/icons/ProfileIcon';
import Link from 'next/link';
import { useSearchContext } from '../../../utils/context/searchContext';
import { PaxContext } from '../../../utils/context/checkoutContext';
import CitiesIcon from '../../../assets/icons/CitiesIcon';

interface BottomBarProps {
  hideBottomBar?: boolean;
}

export const BottomBar = ({ hideBottomBar }: BottomBarProps) => {
  const Menus = [
    {
      path: '/things-to-do',
      label: 'Things To Do',
      redIcon: '/red-thingstodo-bottom-icon.svg',
      grayIcon: '/gray-thingstodo-bottom-icon.svg'
    },
    {
      path: '/',
      label: 'Tours',
      redIcon: '/red-search-icon.svg',
      grayIcon: '/gray-search-icon.svg'
    },
    {
      path: '/cities',
      label: 'Cities',
      redIcon: '/red-search-icon.svg',
      grayIcon: '/gray-search-icon.svg'
    },
    {
      path: '/profile',
      label: 'Profile',
      redIcon: '/red-profile-icon.svg',
      grayIcon: '/gray-profile-icon.svg'
    }
  ];

  const router = useRouter();
  const [activeLink, setActiveLink] = useState(1);
  const { setSelectedCategory, setSearchQuery, setShowFullMap }: any =
    useSearchContext();
  //@ts-ignore

  const { updateBlogCity }: any = useContext(PaxContext);

  useEffect(() => {
    const path = router.pathname;
    if (path === '/profile') setActiveLink(3);
    else if (path === '/things-to-do') setActiveLink(0);
    else if (path === '/') setActiveLink(1);
    else if (path === '/cities') setActiveLink(2);
    else setActiveLink(-1);
  }, [router.pathname]);

  const handleLinkClick = (path: string, activeIndex: number) => {
    setActiveLink(activeIndex);
    setSearchQuery('');
    if (path === '/things-to-do') {
      setSelectedCategory('All Attractions');
      setShowFullMap(false);
    } else {
      updateBlogCity(undefined);
      setSelectedCategory('All Tours');
    }
  };

  return (
    <div
      className={`bg-white px-3 sm:px-6 fixed bottom-0 w-full ${
        hideBottomBar ? `z-[2]` : `z-[1100]`
      } h-[80px] flex items-center`}
      style={{ filter: 'drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.12))' }}
    >
      <ul className="flex w-full justify-between mt-2">
        {Menus.map((menu, i) => (
          <li key={i} className="w-full flex justify-center items-center">
            <Link
              className="flex flex-col justify-center items-center gap-1 text-center w-full"
              onClick={() => handleLinkClick(menu.path, i)}
              href={menu.path}
            >
              {activeLink === i && (
                <span
                  className={`bg-primary duration-500 border-8 border-lightGray h-8 w-8 absolute -top-2 rounded-full transition-transform ease-in-out`}
                  style={{
                    filter: 'drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.12))'
                  }}
                />
              )}
              <span className="cursor-pointer duration-500">
                {i === 0 && (
                  <ThingsToDoIcon color={activeLink === 0 ? 'red' : 'gray'} />
                )}
                {i === 1 && (
                  <ToursIcon color={activeLink === 1 ? 'red' : 'gray'} />
                )}
                {i === 2 && (
                  <CitiesIcon color={activeLink === 2 ? 'red' : 'gray'} />
                )}
                {i === 3 && (
                  <ProfileIcon color={activeLink === 3 ? 'red' : 'gray'} />
                )}
              </span>
              <span
                className={`text-[11px] xsm:text-xs font-semibold ${
                  activeLink === i ? 'text-primary' : 'text-black13 opacity-60'
                }`}
              >
                {menu.label}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
