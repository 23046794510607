import { RefObject } from 'react';
import AttractionCard from '../../../shared-components/AttractionCard/AttractionCard';
import { EAttractionCardPageType } from '../../../types/components/attraction-card';

interface AttractionsGridProps {
  hits: any[];
  // lastAttractionElementRef?: (node: HTMLElement | null) => void;
}

export const AttractionsGrid: React.FC<AttractionsGridProps> = ({
  hits
  // lastAttractionElementRef
}) => (
  <div className="grid grid-cols-1 mx-5 xsm:mx-6 md:mx-0 mmsm:grid-cols-2 md:grid-cols-2 mmd:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-5">
    {hits.map((cardDetails, index) => (
      <AttractionCard
        attractionCard={cardDetails}
        key={index}
        index={index + 1}
        isMapCard={false}
        pageType={EAttractionCardPageType.LANDING_PAGE}
        // ref={index === hits.length - 1 ? lastAttractionElementRef : null}
      />
    ))}
  </div>
);
