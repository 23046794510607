import React from 'react';

interface Props {
  color: 'red' | 'gray';
}

const ProfileIcon: React.FC<Props> = ({ color }) => {
  const iconColor = color === 'red' ? '#F15C5A' : '#717171';

  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1427 16.7145C17.9645 14.6466 16.1247 13.0353 13.9194 12.14C15.0161 11.3176 15.8261 10.1709 16.2349 8.86245C16.6436 7.554 16.6303 6.15013 16.1968 4.84968C15.7633 3.54923 14.9316 2.41813 13.8196 1.61661C12.7075 0.815096 11.3715 0.383789 10.0007 0.383789C8.6299 0.383789 7.29385 0.815096 6.18181 1.61661C5.06976 2.41813 4.23809 3.54923 3.80461 4.84968C3.37112 6.15013 3.3578 7.554 3.76653 8.86245C4.17525 10.1709 4.98531 11.3176 6.08194 12.14C3.87672 13.0353 2.0369 14.6466 0.858663 16.7145C0.785655 16.8319 0.736995 16.9628 0.715577 17.0993C0.694159 17.2359 0.700419 17.3754 0.733986 17.5095C0.767552 17.6436 0.82774 17.7696 0.910971 17.88C0.994202 17.9904 1.09878 18.0829 1.21848 18.152C1.33818 18.2212 1.47056 18.2656 1.60776 18.2826C1.74496 18.2995 1.88417 18.2887 2.01712 18.2508C2.15006 18.2129 2.27403 18.1487 2.38165 18.0619C2.48927 17.9751 2.57835 17.8676 2.64358 17.7457C4.20077 15.0542 6.95077 13.4489 10.0007 13.4489C13.0506 13.4489 15.8006 15.055 17.3578 17.7457C17.4992 17.9731 17.7234 18.1365 17.9831 18.2015C18.2428 18.2665 18.5176 18.228 18.7494 18.0941C18.9812 17.9601 19.1518 17.7413 19.2252 17.4839C19.2986 17.2264 19.2691 16.9505 19.1427 16.7145ZM5.53194 6.91762C5.53194 6.03378 5.79403 5.1698 6.28506 4.43491C6.7761 3.70003 7.47402 3.12726 8.29058 2.78903C9.10713 2.4508 10.0057 2.36231 10.8725 2.53473C11.7394 2.70716 12.5356 3.13277 13.1606 3.75773C13.7855 4.3827 14.2112 5.17895 14.3836 6.04581C14.556 6.91266 14.4675 7.81118 14.1293 8.62773C13.7911 9.44429 13.2183 10.1422 12.4834 10.6332C11.7485 11.1243 10.8845 11.3864 10.0007 11.3864C8.81593 11.385 7.68008 10.9138 6.84232 10.076C6.00456 9.23824 5.53331 8.10238 5.53194 6.91762Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default ProfileIcon;
