import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  AttractionCardType,
  EAttractionCardPageType
} from '../../types/components/attraction-card';
import { LoadingSpinner } from '../../shared-components/LoadingSpinner/LoadingSpinner';
import Image from 'next/image';
import { useInstantSearch } from 'react-instantsearch';
import { Map } from '../ThingsToDo/Map/Map';
import { useSearchContext } from '../../utils/context/searchContext';
import { DEFAULT_DROPDOWN_ATTRACTIONS } from '../../pages/things-to-do';
import { useMediaQuery } from '../../hooks/mediaQuery';
import { LoadMoreButton } from './components/LoadMoreButton';
import { ShowMapToggleButton } from './components/ShowMapToggleButton';
import { AttractionsGrid } from './components/AttractionsGrid';
import { useDebounce } from '../../utils/debounce';
interface IProps {
  hits: AttractionCardType[];
  totalPages: number;
  hitsPerPage: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  handleLoadMore: () => void;
  isLoadingMoreAttractions: boolean;
  isLoadingSearchedAttractions: boolean;
  radius: number;
  setRadius?: Dispatch<SetStateAction<number>>;
  uniqueAttractionsThingsTodo: AttractionCardType[] | undefined;
  mapRef: React.RefObject<L.Map> | any;
}
const LandingPage = ({
  hits,
  totalPages,
  currentPage,
  setCurrentPage,
  handleLoadMore,
  isLoadingMoreAttractions,
  isLoadingSearchedAttractions,
  radius,
  setRadius,
  uniqueAttractionsThingsTodo,
  mapRef
}: IProps) => {
  const { status } = useInstantSearch();
  const [showInitialLoading, setShowInitialLoading] = useState(false);
  const cardsRef: any = useRef(null);
  const {
    showFullMap,
    setShowFullMap,
    setSelectedAttraction,
    selectedAttraction,
    setSearchQuery,
    searchQuery,
    setLandingPageCenterPoint,
    selectedCategory
  }: any = useSearchContext();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const isMobileScreen = useMediaQuery(640);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    if (
      status === 'idle' &&
      hits.length === 0 &&
      searchQuery == '' &&
      !showFullMap
    ) {
      setShowInitialLoading(true);
    } else {
      setShowInitialLoading(false);
    }
  }, [status, hits]);

  // const lastAttractionElementRef = useCallback(
  //   (node: HTMLElement | null) => {
  //     if (isLoadingMoreAttractions || !node || isMobileScreen) return;

  //     if (observer.current) observer.current.disconnect();

  //     observer.current = new IntersectionObserver(entries => {
  //       if (entries[0].isIntersecting && currentPage < totalPages) {
  //         handleLoadMore();
  //       }
  //     });

  //     observer.current.observe(node);
  //   },
  //   [isLoadingMoreAttractions, currentPage, totalPages, handleLoadMore]
  // );

  const toggleSection = () => {
    setShowFullMap(!showFullMap);
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    //reseting center point to view all attractions
    const resetCenterPoint = () => {
      setLandingPageCenterPoint({ lat: null, lng: null });
    };

    if (selectedAttraction) {
      setSelectedAttraction(selectedAttraction);
      resetCenterPoint();
    } else {
      setSearchQuery(searchQuery);
      resetCenterPoint();
    }

    if (showFullMap) {
      window.scrollTo({ top: 40, behavior: 'smooth' });
      setCurrentPage(1);

      if (!searchQuery && selectedCategory == 'All Attractions') {
        setSelectedAttraction(DEFAULT_DROPDOWN_ATTRACTIONS[0]);
      }
    } else {
      window.scrollTo({ top: 20, behavior: 'smooth' });

      if (!searchQuery) {
        setSelectedAttraction(undefined);
      }
    }
  }, [showFullMap]);

  // For Hiding Show Map button on IPHONE while scrolling to the bottom
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Check if we are at the bottom of the page
      if (windowHeight + scrollTop >= documentHeight - 1000) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isLoadingState =
    (hits.length === 0 && (status === 'stalled' || status === 'loading')) ||
    showInitialLoading ||
    isLoadingSearchedAttractions;

  const generateClassNames = (
    showFullMap: boolean,
    hits: any[],
    scrollY: number
  ) => {
    const baseClasses = 'relative scroll-mt-52';

    if (showFullMap) {
      return `${baseClasses} w-[100%] ${
        scrollY > 0 ? '2xl:top-[40px] 3xl:top-[45px]' : 'top-0'
      }`;
    }

    const paddingClasses =
      hits.length <= 4 ? 'pb-16 2xl:pb-[8.5rem]' : '2xl:pb-12';
    const widthClasses =
      'md:w-[85%] xl:w-[90%] 2xl:w-[85%] 3xl:w-[85%] mx-auto mt-8';

    return `${baseClasses} sm:pb-20 ${paddingClasses} ${widthClasses}`;
  };

  const showToggleMapButton =
    (hits.length > 0 || showFullMap) &&
    !isLoadingState &&
    !(isMobileScreen && isBottom && !showFullMap);

  const showLoadMoreButton =
    hits.length > 0 &&
    currentPage < totalPages &&
    !showFullMap &&
    !isLoadingState;

  return (
    <>
      <div
        id="attractions-card"
        ref={cardsRef}
        className={generateClassNames(showFullMap, hits, window?.scrollY)}
      >
        <div className={`${hits.length > 0 ? `mb-0` : `mb-[150px]`}`}>
          {showFullMap ? (
            <Map
              attractionData={hits}
              showFullMap={showFullMap}
              setShowFullMap={setShowFullMap}
              radius={radius}
              setRadius={setRadius}
              mapRef={mapRef}
              pageType={EAttractionCardPageType.LANDING_PAGE}
              uniqueAttractionsThingsTodo={uniqueAttractionsThingsTodo}
            />
          ) : (
            <>
              {isLoadingState ? (
                <div className="flex items-center justify-center h-[550px] flex-col gap-10">
                  <LoadingSpinner className="w-8 h-8 mt-20" />
                </div>
              ) : hits.length === 0 ? (
                <div className="flex items-center justify-center h-[550px] flex-col gap-10">
                  <Image
                    src="https://res.cloudinary.com/see-sight-tours/image/upload/v1709532363/Remaining%20images/Attraction/ekhp3i11sp3dsr7e2poi.png"
                    alt="no-attractions-image"
                    width={300}
                    height={300}
                  />
                  <p className="text-base font-normal">
                    Currently no attractions available
                  </p>
                </div>
              ) : (
                <AttractionsGrid
                  hits={hits}
                  // lastAttractionElementRef={lastAttractionElementRef}
                />
              )}
            </>
          )}
        </div>

        {showToggleMapButton && (
          <ShowMapToggleButton
            showFullMap={showFullMap}
            toggleSection={toggleSection}
            hits={hits}
          />
        )}
      </div>

      {showLoadMoreButton && (
        <LoadMoreButton
          handleLoadMore={handleLoadMore}
          isLoadingMoreAttractions={isLoadingMoreAttractions}
        />
      )}
    </>
  );
};

export default LandingPage;
