import React, { createContext, useState } from 'react';

export const AttractionCheckoutContext = createContext();

export const AttractionCheckoutProvider = ({ children }) => {
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [attraction, setAttraction] = useState({});

  const updateCheckoutDetails = newDetails => {
    setCheckoutDetails(newDetails);
  };

  const updateAttraction = newAttraction => {
    setAttraction(newAttraction);
  };
  const contextValue = {
    checkoutDetails,
    updateCheckoutDetails,
    attraction,
    updateAttraction
  };

  return (
    <AttractionCheckoutContext.Provider value={contextValue}>
      {children}
    </AttractionCheckoutContext.Provider>
  );
};
