import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import SwiperCore, { Autoplay, Navigation, EffectFade } from 'swiper';
SwiperCore.use([Autoplay, Navigation, EffectFade]);

import Head from 'next/head';
import { InstantSearch } from 'react-instantsearch';

import { ELandingPage, TourCardType } from '../types/pages/landing-page';
import { useSearchContext } from '../utils/context/searchContext';
import { useDebounce } from '../utils/debounce';
import { CustomSearchBox } from '../shared-components/SearchBar/SearchBar';
import AttractionCategories from '../components/Landingpage/components/attractionCategories';
import LandingPageTours from '../components/Tours/LandingPageTours';
import Newsletter from '../layouts/Newsletter/Newsletter';
import client from '../apollo-client';
import { FETCH_ALL_CITIES } from '../api/citiesPage';
import replaceText from '../utils/trip-sheppered-text';
import { useUpdateQueryParams } from '../utils/useUpdateQueryParams';
import { GetStaticProps } from 'next';
import { RelatedUrl } from '../types/pages/things-to-do';
import { ATTRACTIONS_BY_POPULAR_CITIES } from '../api/attraction';
import { RelatedBreadcrumbLinks } from '../components/ThingsToDo/RelatedBreadcrumbLinks/RelatedBreadcrumbLinks';
import axios from 'axios';
import { LoadingSpinner } from '../shared-components/LoadingSpinner/LoadingSpinner';
import { DummySearch } from '../shared-components/SearchBar/DummySearchBar';
import LandingPageTourCard from '../components/Tours/LandingPageTourCard';
import algoliasearch from 'algoliasearch/lite';

interface BreadcrumbLink {
  id: string;
  name: string;
  slug: string;
}

interface ToursProps {
  popular_cities_breadcrumbs: RelatedUrl[];
  initialTours: TourCardType[];
  initialCurrentPage: number;
  initialTotalPages: number;
}

export const DEFAULT_DROPDOWN_CITIES = [
  {
    name: 'Niagara Falls, Canada',
    country: 'Canada',
    objectID: '19a8fbd2a47635_dashboard_generated_id',
    id: '7ed593f2-07e5-4896-8453-df88a724d799'
  },
  {
    name: 'Halifax',
    country: 'Canada',
    objectID: '1c669b8bfee4f9_dashboard_generated_id',
    id: '5c10f4b8-115b-42b5-b278-5bb38b4976f4'
  },
  {
    name: 'Toronto',
    country: 'Canada',
    objectID: 'c1460d6155952_dashboard_generated_id',
    id: '63afd0b1-cefc-467e-b84a-278307b6623e'
  }
];

const Tours = ({
  popular_cities_breadcrumbs,
  initialCurrentPage,
  initialTotalPages,
  initialTours
}: ToursProps) => {
  const {
    searchQuery,
    setSearchQuery,
    dropdownTours,
    setDropdownTours,
    dropdownCities,
    setDropdownCities,
    selectedTourCity,
    setSelectedTourCity,
    selectedCategory,
    setSelectedCategory
  }: any = useSearchContext();
  const updateQueryParams = useUpdateQueryParams();
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollOccurred, setScrollOccurred] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [tours, setTours] = useState<TourCardType[]>(initialTours);
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [appendMode, setAppendMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  // State to manage when to switch from DummySearch to InstantSearch (doing it to get it in source code)
  const [showInstantSearch, setShowInstantSearch] = useState(false);

  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
  );
  const [isMobileScreen, setIsMobileScreen] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 640
  );
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  // Determine hits per page based on screen size
  const HITS_PER_PAGE = isMobileScreen ? 10 : 20;

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth <= 640);
    };

    window.addEventListener('resize', checkScreenSize);
    setTimeout(() => {
      setShowBreadcrumbs(true);
    }, 100);
    checkScreenSize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const getClassName = () => {
    if (!isScrolled && scrollOccurred) {
      return 'transition-all duration-300 animate-fade-down';
    } else if (isScrolled) {
      return 'transition-all duration-300 animate-fade-up hidden';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        currentPage === totalPages && setShowNewsletter(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(() => {
      if (window.scrollY > 20) {
        window.scrollTo({ top: 20, behavior: 'smooth' });
      }
    }, 1000);
  }, [searchQuery, selectedCategory]);

  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
      if (!scrollOccurred && scrollPosition > 0) {
        setScrollOccurred(true);
      }
    };
  }

  useEffect(() => {
    setSelectedCategory('All Tours');
  }, []);

  const tourCategories = [
    {
      id: '0',
      label: 'All Tours',
      value: 'All Tours',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248139/icons-website/h8fhrijhtbxorqla8urd.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248152/icons-website/xkvqwyylizzcnbiz82oz.svg'
    },
    {
      id: '1',
      label: 'Day Tours',
      value: 'Day Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/gray-day-tour_yycy5r.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-day-tour_yewcju.svg'
    },
    {
      id: '2',
      label: 'Walking Tours',
      value: 'Walking Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373531/icons-website/gray-walking-tour_lk1ree.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-walking-tour_w8gv5d.svg'
    },
    {
      id: '3',
      label: 'Food Walking Tours',
      value: 'Food Walking Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373531/icons-website/gray-food-walking-tour_u8x8p5.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-food-walking-tour_gwdmup.svg'
    },
    {
      id: '4',
      label: 'Night Tours',
      value: 'Night Tour',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/gray-night-tour_dxqlma.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1721373524/icons-website/red-night-tour_git1d4.svg'
    },
    {
      id: '5',
      label: 'Cruises',
      value: 'Cruises',
      grayIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248189/icons-website/rqyxrqouu55yjhkwjy0m.svg',
      redIcon:
        'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248202/icons-website/zmrsxuxktbmbavdshv6j.svg'
    }
  ];

  const toursIndex = searchClient.initIndex('tours');
  const cityIndex = searchClient.initIndex('cities');

  const fetchCities = (query: string, tours: TourCardType[]) => {
    setIsLoading(true);

    cityIndex
      .search(query, {
        hitsPerPage: 50
      })
      .then((response: any) => {
        const { hits } = response;
        const modifiedCities = hits.map((city: any) => ({
          ...city,
          id: city.id
        }));

        // Filter cities from search results whose tours match the selected category
        const filteredCities = modifiedCities.filter((searchCity: any) => {
          // Check if any tour in the tours list matches this city and has the selected category
          return tours?.some((tour: any) => {
            return (
              tour.city === searchCity.name &&
              tour.category.some(
                (category: string) => category === selectedCategory
              )
            );
          });
        });

        // Set dropdownCities with the filtered cities (up to 3)
        setDropdownCities(
          selectedCategory !== 'All Tours'
            ? filteredCities?.slice(0, 3)
            : modifiedCities?.slice(0, 3)
        );
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Fetch Tours from Algolia

  const fetchAlgoliaTours = (
    query: string,
    city: string,
    category: string,
    page: number,
    append: boolean
  ) => {
    setIsLoading(true);

    //For category and city filter
    const facetFilters: any = [];
    if (
      (category && category == 'All Tours') ||
      category == 'All Attractions'
    ) {
    } else {
      facetFilters.push(`category:${category}`);
    }
    if (city) {
      facetFilters.push(`city:${city}`);
    }

    toursIndex
      .search(query, {
        page: page - 1,
        hitsPerPage: HITS_PER_PAGE,
        facetFilters: facetFilters
      })
      .then((response: any) => {
        const { hits, nbPages } = response;
        const modifiedTours = hits.map((tour: any) => ({
          ...tour,
          id: tour.objectID
        }));

        if (append) {
          setTours(prevTours => [...prevTours, ...modifiedTours]);
        } else {
          setTours(modifiedTours);
        }

        if (page === 1) setDropdownTours(modifiedTours?.slice(0, 2));
        setTotalPages(nbPages);
        fetchCities(query, tours);
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchApiTours = async (
    query: string,
    city: string,
    category: string,
    page: number,
    append: boolean = false
  ) => {
    try {
      setIsLoading(true);

      const limit = 20;

      let url = `${process.env.NEXT_PUBLIC_FIREBASE_CONNECTION}/web-products?page=${page}&limit=${limit}`;

      // Append city if it is not empty
      if (city) {
        url += `&city=${city}`;
      }

      // Append category if it is not "All Tours"
      if (category && category !== 'All Tours') {
        url += `&category=${category}`;
      }

      const response = await axios.get(url);
      const fetchedTours = response.data.productsList;
      setTotalPages(response.data.totalPages);

      if (append) {
        // Append to existing tours
        setTours((prevTours: TourCardType[]) => [
          ...prevTours,
          ...fetchedTours
        ]);
      } else {
        // Replace tours with the newly fetched data
        setTours(fetchedTours);
      }
      if (page === 1) setDropdownTours(fetchedTours?.slice(0, 2));
    } catch (error) {
      console.error('Error fetching tours:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearchQuery = useDebounce(query => {
    if (query) {
      // Condition for fetching from API when both query and selectedTourCity exist
      if (selectedTourCity && query) {
        fetchApiTours(query, selectedTourCity, selectedCategory, 1, false);
      } else {
        // Fetch from Algolia for all other cases when there is a search query
        fetchAlgoliaTours(query, selectedTourCity, selectedCategory, 1, false);
      }
    } else {
      // Fetch data from API when there is no search query
      fetchApiTours(query, selectedTourCity, selectedCategory, 1, false);
    }
  }, 30);

  useEffect(() => {
    // Only call on user interaction after initial load
    if (!initialLoad) {
      setAppendMode(false);
      debouncedSearchQuery(searchQuery);
    }
  }, [searchQuery, selectedCategory, selectedTourCity]);

  useEffect(() => {
    // Set the initial load to false after the component mounts
    if (initialLoad) {
      setDropdownTours(tours?.slice(0, 2));
      setInitialLoad(false);
    } else {
      // When Load More button is clicked or category/city is selected

      if (searchQuery) {
        fetchAlgoliaTours(
          searchQuery,
          selectedTourCity,
          selectedCategory,
          currentPage,
          appendMode ? true : false
        );
      } else {
        fetchApiTours(
          searchQuery,
          selectedTourCity,
          selectedCategory,
          currentPage,
          appendMode ? true : false
        );
      }
    }
  }, [currentPage, appendMode]);

  const handleLoadMore = () => {
    setAppendMode(true);
    setCurrentPage((prevPage: number) => prevPage + 1);
  };

  const handleCategoryClick = (category: string) => {
    // Update the selected category and other state variables
    setSelectedCategory(category);
    setCurrentPage(1);
    setAppendMode(false);
    setSearchQuery('');
    setSelectedTourCity('');

    // Update the URL without the 'q' parameter and add the 'category' parameter
    updateQueryParams({ q: '', category: category }, '/');
  };

  useEffect(() => {
    // Switch to InstantSearch after 0.01 seconds
    const timer = setTimeout(() => {
      setShowInstantSearch(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="og:title"
          content={
            'Tripshepherd (Formerly See Sight Tours) - Best Small Group Tours'
          }
        ></meta>
        <meta
          name="og:description"
          content={
            'Explore Canada and the USA with Tripshepherd (formerly See Sight Tours) offering the best small-group tours. Discover the best destinations, attractions, and top things to do with local tour guides.'
          }
        />
        <title>
          Tripshepherd (Formerly See Sight Tours) - Best Small Group Tours
        </title>
        <meta
          name="description"
          content={
            'Explore Canada and the USA with Tripshepherd (formerly See Sight Tours) offering the best small-group tours. Discover the best destinations, attractions, and top things to do with local tour guides.'
          }
        />
        <link href={'https://www.tripshepherd.com/'} rel="canonical" />

        <meta
          name="og:image"
          content="https://res.cloudinary.com/see-sight-tours/image/upload/v1704291035/Trip_sheperd_2_mfyxdg.webp"
        />
      </Head>

      <main>
        <div className="min-h-screen">
          {showInstantSearch ? (
            <InstantSearch
              searchClient={searchClient}
              indexName="tours"
              future={{ preserveSharedStateOnUnmount: true }}
            >
              <div
                className={`sticky top-[68px] lg:top-[80px] 3xl:top-[80px] z-[1020] bg-white `}
              >
                <div
                  className={`relative z-[1000] w-[100%] bg-white ${
                    isScrolled ? `px-5` : ` py-3`
                  }
 ${getClassName()}`}
                >
                  <CustomSearchBox
                    width="w-[90%] mmsm:w-[65%] sm:w-[55%] md:w-[60%] mmd:w-[50%] lg:w-[40%] xl:w-[35%] 3xl:w-[30%]"
                    height="h-[48px] md:h-[50px]"
                    iconStyles={{
                      search: 'top-[4.5px] md:top-[7.5px] xl:top-[8px]',
                      clear: 'xxsm:top-[7px] md:top-[8px]'
                    }}
                    searchTerm={searchQuery}
                    setSearchTerm={setSearchQuery}
                    defaultCities={
                      searchQuery ? dropdownCities : DEFAULT_DROPDOWN_CITIES
                    }
                    defaultTours={dropdownTours}
                    pagetype={ELandingPage.TOURS_PAGE}
                    setSelectedCity={setSelectedTourCity}
                  />
                </div>

                <AttractionCategories
                  categories={tourCategories}
                  pagetype={ELandingPage.TOURS_PAGE}
                  handleCategoryClick={handleCategoryClick}
                  selectedCategory={selectedCategory}
                />
              </div>
              <div className="mx-5 xsm:mx-6 md:mx-0">
                <LandingPageTours
                  hits={JSON.parse(replaceText(JSON.stringify(tours)))}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isLoading={isLoading}
                  totalPages={totalPages}
                  handleLoadMore={handleLoadMore}
                />
              </div>
            </InstantSearch>
          ) : (
            <div
              className={`sticky top-[68px] lg:top-[80px] 3xl:top-[80px] z-[1020] bg-white `}
            >
              <div
                className={`relative z-[1000] w-[100%] bg-white ${
                  isScrolled ? `px-5` : ` py-3`
                }
    ${getClassName()}`}
              >
                <DummySearch
                  width="w-[90%] mmsm:w-[65%] sm:w-[55%] md:w-[60%] mmd:w-[50%] lg:w-[40%] xl:w-[35%] 3xl:w-[30%]"
                  height="h-[48px] md:h-[50px]"
                  iconStyles={{
                    search: 'top-[4.5px] md:top-[7.5px] xl:top-[8px]',
                    clear: 'xxsm:top-[7px] md:top-[8px]'
                  }}
                  searchTerm={searchQuery}
                  setSearchTerm={setSearchQuery}
                  defaultCities={
                    searchQuery ? dropdownCities : DEFAULT_DROPDOWN_CITIES
                  }
                  defaultTours={dropdownTours}
                  pagetype={ELandingPage.TOURS_PAGE}
                  setSelectedCity={setSelectedTourCity}
                />
                <AttractionCategories
                  categories={tourCategories}
                  pagetype={ELandingPage.TOURS_PAGE}
                  handleCategoryClick={handleCategoryClick}
                  selectedCategory={selectedCategory}
                />
                {initialTours && !searchQuery && (
                  <div className="mx-5 xsm:mx-6 md:mx-0">
                    <div
                      id="tour_cards"
                      className={`w-[100%] md:w-[90%] xl:w-[93%] 2xl:w-[90%] mx-auto mt-8 scroll-mt-52 min-h-screen ${
                        currentPage < totalPages && `mb-[8rem]`
                      }`}
                    >
                      <div
                        className={`grid grid-cols-1 mmsm:grid-cols-2 md:grid-cols-2 mmd:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-[18px]`}
                      >
                        {tours?.map((cardDetails, index) => (
                          <LandingPageTourCard
                            tourCard={cardDetails}
                            key={cardDetails.objectID}
                            index={index + 1}
                            // ref={index === hits.length - 1 ? lastTourElementRef : null}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* {!showBreadcrumbs && initialTours && (
          <div className="flex justify-center items-center h-[550px]">
            <LoadingSpinner className="h-5 w-5" />
          </div>
        )} */}

        {/* {!isLoading && showBreadcrumbs && ( */}
        <div
          className={`bottom-[90px] relative 
            xl:h-min-[300px] ${
              currentPage === totalPages ? `mt-[7rem]` : `mb-5`
            }`}
        >
          {popular_cities_breadcrumbs ? (
            <RelatedBreadcrumbLinks
              breadcrumbs={popular_cities_breadcrumbs}
              pagetype={ELandingPage.TOURS_PAGE}
            />
          ) : (
            <LoadingSpinner className="w-8 h-8" />
          )}
        </div>
        {/* )} */}
        {showNewsletter && <Newsletter />}
      </main>
    </>
  );
};

export default Tours;

export const getStaticProps: GetStaticProps = async () => {
  try {
    // Execute queries and API call in parallel
    const [citiesResponse, attractionsResponse, toursResponse] =
      await Promise.all([
        client.query({ query: FETCH_ALL_CITIES }),
        client.query({
          query: ATTRACTIONS_BY_POPULAR_CITIES,
          variables: { cityIds: ['17', '2', '13', '18'] }
        }),
        axios.get(
          `${process.env.NEXT_PUBLIC_FIREBASE_CONNECTION}/web-products?page=1&limit=20`
        )
      ]);

    // Process cities and attractions data
    const cities =
      citiesResponse.data?.citiesPage?.cities?.map((item: any) => ({
        id: item.city.id,
        name: item.city.name,
        url: item.city.slug
      })) || [];

    const attractions =
      attractionsResponse.data?.cities?.flatMap((city: any) =>
        city.attractions.map((attraction: BreadcrumbLink) => ({
          name: attraction.name,
          url: attraction.slug
        }))
      ) || [];

    // Process tours data from the API
    const tours = toursResponse.data.productsList || [];
    const currentPage = toursResponse.data.currentPage || 1;
    const totalPages = toursResponse.data.totalPages || 1;

    // Construct breadcrumbs
    const relatedBreadcrumbs = [
      {
        parent: 'Popular Cities',
        links: cities
      },
      {
        parent: 'Popular Attractions',
        links: attractions
      }
    ];

    return {
      props: {
        popular_cities_breadcrumbs: relatedBreadcrumbs,
        initialTours: JSON.parse(replaceText(JSON.stringify(tours))),
        initialCurrentPage: currentPage,
        initialTotalPages: totalPages
      },
      // Revalidate after 10 minutes (ISR)
      revalidate: 600
    };
  } catch (error) {
    console.error('Error fetching data:', error);

    // Fallback data if an error occurs
    return {
      props: {
        popular_cities_breadcrumbs: [],
        tours: [],
        currentPage: 1,
        totalPages: 1
      }
    };
  }
};
