import { gql } from '@apollo/client';

const SUBSCRIBE_TO_NEWSLETTER = gql`
  mutation SubscribeToNewsletter($status: String, $email: String) {
    insert_boatnew_news_letter_subscribers(
      objects: { status: $status, email: $email }
    ) {
      affected_rows
    }
  }
`;
export default SUBSCRIBE_TO_NEWSLETTER;
