import Image from 'next/image';

export const SocialMediaIconLinks = () => (
  <div className="flex gap-4 md:gap-3">
    {[
      {
        href: 'https://www.instagram.com/tripshepherd/',
        src: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263648/f6jqxiglvzal2dafglqj.svg',
        alt: 'Instagram'
      },
      {
        href: 'https://www.facebook.com/tripshepherd/',
        src: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/qymiqavkrqdhr5yhtkdx.svg',
        alt: 'Facebook'
      },
      {
        href: 'https://www.pinterest.ca/Tripshepherd/',
        src: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/wrb0blpujbh0lklymtqc.svg',
        alt: 'Pinterest'
      },
      {
        href: 'https://x.com/tripshepherdINC',
        src: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/idhwbmmwevpliasgagg8.svg',
        alt: 'Twitter'
      },
      {
        href: 'https://www.youtube.com/user/SeeSightTours',
        src: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263646/m3ltnz8zck5oqotitamu.svg',
        alt: 'YouTube'
      },
      {
        href: 'https://www.linkedin.com/company/tripshepherd',
        src: 'https://res.cloudinary.com/see-sight-tours/image/upload/v1713263647/msnxb1efmgranonrfzlw.svg',
        alt: 'LinkedIn'
      }
    ].map((icon, index: number) => (
      <a
        key={index}
        href={icon.href}
        target="_blank"
        rel="noreferrer noopener"
        aria-label={`This is an external link (opens in a new tab) to ${icon.alt}`}
      >
        <Image
          src={icon.src}
          width={38}
          quality={100}
          height={38}
          alt={icon.alt}
        />
      </a>
    ))}
  </div>
);
