import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ThingsToDO } from '../../../types/pages/things-to-do';
import axios, { AxiosError } from 'axios';
import dynamic from 'next/dynamic';
import {
  AttractionCardType,
  Center,
  EAttractionCardPageType,
  ESectionInView
} from '../../../types/components/attraction-card';
import { useDebounce } from '../../../utils/debounce';
const DynamicMap = dynamic(() => import('./MapContainer'), {
  ssr: false
});

interface IProps {
  attractionData: AttractionCardType[] | undefined;
  setAttractionData?: Dispatch<
    SetStateAction<AttractionCardType[] | undefined>
  >;
  thingsToDoDetails?: ThingsToDO;
  showFullMap: boolean;
  setShowFullMap?: Dispatch<SetStateAction<boolean>>;
  radius: number;
  setRadius?: Dispatch<SetStateAction<number>>;
  sectionInView?: ESectionInView;
  mapRef: React.RefObject<L.Map> | any;
  pageType: EAttractionCardPageType;
  uniqueAttractionsThingsTodo?: AttractionCardType[] | undefined;
  hoveredAttractionId?: string;
}
export interface AttractionRadiusRequest {
  center: [number | null, number | null];
  radius: number;
  cityId: string | undefined;
}

export const Map = ({
  attractionData,
  setAttractionData,
  thingsToDoDetails,
  showFullMap,
  setShowFullMap,
  radius,
  setRadius,
  sectionInView,
  pageType,
  uniqueAttractionsThingsTodo,
  hoveredAttractionId,
  mapRef
}: IProps) => {
  const [centerPoint, setCenterPoint] = useState<Center>({
    lat: thingsToDoDetails?.city?.coordinates
      ? thingsToDoDetails?.city?.coordinates?.lat
      : null,
    lng: thingsToDoDetails?.city?.coordinates
      ? thingsToDoDetails?.city?.coordinates?.lng
      : null
  });
  const data: AttractionRadiusRequest = {
    center: [centerPoint.lat, centerPoint.lng],
    radius: radius,
    cityId: thingsToDoDetails?.city?.id
  };

  async function postAttractionRadius(data: AttractionRadiusRequest) {
    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_FIREBASE_CONNECTION}/attraction-radius`,
        data
      );
      setAttractionData && setAttractionData(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios Error:', axiosError.message);
        console.error('Response data:', axiosError.response?.data);
        console.error('Status code:', axiosError.response?.status);
      } else {
        console.error('Error:', error);
      }
    }
  }

  const debouncedPostAttractionRadius = useDebounce(postAttractionRadius, 300);

  useEffect(() => {
    // Call the debounced function when the radius changes
    debouncedPostAttractionRadius(data);

    // Add event listener to prevent API call on scroll
    const handleScroll = () => {
      debouncedPostAttractionRadius.cancel();
    };

    attractionData !== undefined &&
      window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      debouncedPostAttractionRadius.cancel();
    };
  }, [radius]);

  return (
    <div
      className={`flex flex-col ${
        showFullMap
          ? `w-full ${
              pageType == EAttractionCardPageType.LANDING_PAGE
                ? `h-screen 2xl:h-[calc(100vh-180px)] 3xl:h-[calc(100vh-190px)]`
                : `h-screen`
            }`
          : sectionInView === ESectionInView.MAP
          ? 'sm:flex sm:h-[calc(100vh-220px)] mlg:h-full w-full mlg:w-[48%] xl:w-[42%] 2xl:w-[40%] 3xl:w-[45%]'
          : 'sm:hidden mlg:flex mlg:w-[48%] xl:w-[42%] 2xl:w-[40%] 3xl:w-[45%] mlg:h-full'
      } relative`}
    >
      <DynamicMap
        setRadius={setRadius}
        setCenterPoint={setCenterPoint}
        attractionData={attractionData}
        city={thingsToDoDetails?.city}
        setShowFullMap={setShowFullMap}
        showFullMap={showFullMap}
        mapRef={mapRef}
        pageType={pageType}
        hoveredAttractionId={hoveredAttractionId}
      />
    </div>
  );
};
