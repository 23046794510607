import React from 'react';

interface Props {
  color: 'red' | 'gray';
}

const ThingsToDoBottomIcon: React.FC<Props> = ({ color }) => {
  const iconColor = color === 'red' ? '#F15C5A' : '#717171';

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2066 2.29481C12.8666 2.01154 12.3729 2.01154 12.0329 2.29481L8.88034 4.92204C8.6234 5.13612 8.33722 5.29628 8.03646 5.40251V16.1959C8.03646 16.2581 8.03025 16.3189 8.01844 16.3777C8.03025 16.4364 8.03646 16.4972 8.03646 16.5594C8.03646 17.0657 7.62605 17.4761 7.11979 17.4761C6.49526 17.4761 5.86909 17.2635 5.35929 16.8387L1.69262 13.7831C1.06564 13.2606 0.703125 12.4867 0.703125 11.6705V2.56968C0.703125 1.01531 2.51603 0.166187 3.71013 1.16127L6.53296 3.51363C6.8729 3.79691 7.36669 3.79691 7.70662 3.51362L10.8592 0.886412C11.8791 0.0365607 13.3605 0.0365607 14.3803 0.886412L18.047 3.94197C18.4359 4.26607 18.4885 4.84408 18.1643 5.23301C17.8402 5.62193 17.2623 5.67447 16.8733 5.35038L13.2066 2.29481ZM6.20312 15.1555V5.40251C5.90239 5.29628 5.61619 5.13612 5.35929 4.92204L2.53646 2.56968V11.6705C2.53646 11.9426 2.65729 12.2006 2.86629 12.3748L6.20312 15.1555ZM14.4531 7.94589C12.9343 7.94589 11.7031 9.17707 11.7031 10.6959C11.7031 11.0176 11.819 11.4784 12.0678 12.0364C12.3101 12.58 12.6489 13.1508 13.0218 13.6721C13.3956 14.1951 13.785 14.6425 14.1157 14.9481C14.2516 15.0738 14.3653 15.1639 14.4531 15.2236C14.5409 15.1639 14.6546 15.0738 14.7905 14.9481C15.1213 14.6425 15.5107 14.1951 15.8845 13.6721C16.2573 13.1508 16.5962 12.58 16.8385 12.0364C17.0873 11.4784 17.2031 11.0176 17.2031 10.6959C17.2031 9.17707 15.972 7.94589 14.4531 7.94589ZM9.86979 10.6959C9.86979 8.16461 11.9218 6.11256 14.4531 6.11256C16.9844 6.11256 19.0365 8.16461 19.0365 10.6959C19.0365 11.3867 18.8086 12.1197 18.513 12.7828C18.2111 13.4603 17.8052 14.1379 17.3759 14.7384C16.9476 15.3373 16.4777 15.8852 16.0349 16.2944C15.8146 16.498 15.5826 16.6847 15.3498 16.8261C15.1421 16.9522 14.8222 17.1126 14.4531 17.1126C14.0841 17.1126 13.7642 16.9522 13.5564 16.8261C13.3237 16.6847 13.0916 16.498 12.8713 16.2944C12.4286 15.8852 11.9586 15.3373 11.5303 14.7384C11.1011 14.1379 10.6952 13.4603 10.3932 12.7828C10.0977 12.1197 9.86979 11.3867 9.86979 10.6959Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default ThingsToDoBottomIcon;
