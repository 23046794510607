import React, { useState, useEffect, useRef, FormEvent } from 'react';
import axios from 'axios';
import Image from 'next/image';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { styled } from 'styled-components';

interface SurveyQuestion {
  id: string;
  questionId: string;
  text: string;
  type: 'radio' | 'text';
  options?: string[];
}

interface UserResponses {
  [questionId: string]: string | string[];
}

const StyledTextarea = styled.textarea`
  padding: 14px;
  font-size: 16px;
  width: 133.333%;
  transform: scale(0.75);
  transform-origin: left top;
  margin-bottom: -10px;

  @media (min-width: 640px) {
    font-size: 15px;
    margin-bottom: 0;
    transform: scale(1);
    width: 100%;
  }

  @media (min-width: 1920px) {
    font-size: 17px;
  }

  border: 1px solid #e5e7eb;
  border-radius: 8px;
  resize: none;
  box-sizing: border-box;
`;

const FeedBackButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [survey, setSurvey] = useState<SurveyQuestion[]>([]);
  const [userResponses, setUserResponses] = useState<UserResponses>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();

  // Function to get current page name and ID based on the route
  const getPageInfo = (): { pageName: string; pageId: string } => {
    if (router.route === '/checkout')
      return { pageName: 'Checkout Page', pageId: '3' };
    if (router.route.startsWith('/tour'))
      return { pageName: 'Product Page', pageId: '2' };
    return { pageName: 'Landing Page', pageId: '1' };
  };

  useEffect(() => {
    // Auto-open the modal after 2-3 minutes
    const timeout = setTimeout(() => {
      setIsOpen(true);
    }, 180000); // 3 minutes

    return () => clearTimeout(timeout);
  }, []);
  // Fetch survey data for the current page
  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_FIREBASE_WEBSITE_CONNECTION}/get_all_survey`
        );
        const { pageName } = getPageInfo();
        const pageSurvey: SurveyQuestion[] = response.data.data[pageName] || [];
        setSurvey(pageSurvey);

        // Initialize responses based on the fetched survey
        const initialResponses: UserResponses = {};
        pageSurvey.forEach(question => {
          initialResponses[question.questionId] =
            question.type === 'radio' ? '' : '';
        });
        setUserResponses(initialResponses);
      } catch (err) {
        console.error('Error fetching survey data:', err);
      }
    };

    fetchSurveyData();
  }, [router.route]);

  const submitSurvey = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { pageName, pageId } = getPageInfo();

    // Filter out empty responses
    const filteredSurvey = survey.filter(question => {
      const response = userResponses[question.questionId];
      return response !== '' && response !== undefined;
    });

    if (filteredSurvey.length === 0) {
      // If no questions were answered, show success message and don't send data to API
      toast.success('Survey submitted successfully');
      setUserResponses({});
      setIsOpen(false);
      setIsSubmitting(false);
      return;
    }

    // Create survey array with question text and user responses
    const surveyRequestBody = filteredSurvey.map(question => {
      const response = userResponses[question.questionId];
      return {
        questionId: question.questionId,
        text: question.text,
        userResponse:
          Array.isArray(response) && response.length === 1
            ? response[0]
            : response
      };
    });

    const requestBody = {
      pageId,
      pageName,
      survey: surveyRequestBody
    };

    try {
      await axios.post(
        'https://us-central1-staging-c0818.cloudfunctions.net/website/submit_survey',
        requestBody
      );
      toast.success('Survey submitted successfully');
      setUserResponses({});
      setIsOpen(false);
    } catch (error) {
      console.error('Error submitting survey:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (questionId: string, value: string) => {
    setUserResponses(prevResponses => ({
      ...prevResponses,
      [questionId]: value
    }));
  };

  const renderQuestion = (question: SurveyQuestion) => {
    if (question.type === 'radio') {
      return (
        <div key={question.questionId} className="mb-4">
          <p className="text-sm md:text-base text-[#343434] font-semibold mb-2">
            {question.text}
          </p>
          {question.options?.map(option => (
            <label
              className="flex text-sm md:text-base items-center mb-2"
              key={option}
            >
              <input
                type="radio"
                name={question.questionId}
                value={option}
                className="mr-2 text-gray75"
                checked={userResponses[question.questionId] === option}
                onChange={e =>
                  handleInputChange(question.questionId, e.target.value)
                }
              />
              {option}
            </label>
          ))}
        </div>
      );
    }

    if (question.type === 'text') {
      return (
        <div key={question.questionId} className="md:mb-4">
          <p className="text-sm md:text-base text-[#343434] font-semibold mb-2">
            {question.text}
          </p>
          <StyledTextarea
            name={question.questionId}
            placeholder="Type here..."
            rows={4}
            value={userResponses[question.questionId] || ''}
            onChange={e =>
              handleInputChange(question.questionId, e.target.value)
            }
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className={`fixed ${
          isOpen ? 'opacity-60' : 'opacity-100'
        } right-0 top-1/2 transform -translate-y-1/2 bg-primary text-white py-2 px-4 rounded-l-[20px] z-[1200] flex items-center justify-center`}
        style={{
          width: '36px',
          height: '116px',
          zIndex: 1201
        }}
      >
        <span className="rotate-90">Feedback</span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center pr-10 msm:pr-12 md:pr-14 justify-end z-[1200]">
          <div
            className="bg-white flex w-full rounded-[15px] overflow-hidden relative top-[80px] sm:top-[100px] lg:top-[110px] xl:top-[120px] 3xl:top-[200px] xxsm:w-[270px] xmsm:w-[300px] xsm:w-[320px] md:w-[450px]"
            ref={modalRef}
          >
            <div className="pl-[20px] md:pl-[30px] py-[20px] md:py-[25px] md:pr-[15px] w-[90%] sm:w-[85%] md:w-[90%]">
              <form onSubmit={submitSurvey}>
                {survey.map(question => renderQuestion(question))}
                <button
                  type="submit"
                  className={`bg-primary text-sm md:text-base text-white py-2 px-4 h-[35px] md:h-[42px] rounded-[30px] w-full ${
                    isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </form>
            </div>
            <div className="w-[10%] sm:w-[15%] md:w-[10%] xxsm:mr-[10px] sm:mr-0 flex py-[20px] md:py-[22px] justify-center items-start">
              <button onClick={() => setIsOpen(false)}>
                <Image
                  alt="cross-icon"
                  src="/cross-border-icon.svg"
                  width={30}
                  height={30}
                />
              </button>{' '}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedBackButton;
