import React from 'react';
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import firebase_app from '../../firebase/config';
import LoadingSpinner from '../../shared-components/LoadingSpinner/LoadingSpinner';
import { useRouter } from 'next/router';

const auth = getAuth(firebase_app);

export const AuthContext = React.createContext({});

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const router = useRouter();

  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [gs_url, setGsUrl] = React.useState('');

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem('user_gs_url');
        setGsUrl('');
        router.reload();
      })
      .catch(error => {
        console.log('Error while signing out!', error);
      });
  };

  const updateGsUrl = gsUrl => {
    setGsUrl(gsUrl);
  };

  const value = {
    handleLogout,
    setUser,
    user,
    gs_url,
    updateGsUrl
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
