export type Category = {
  id: string;
  label: string;
  value: string;
  grayIcon?: string;
  redIcon?: string;
};

export enum ELandingPage {
  THINGS_TO_DO_PAGE = 'things-todo',
  TOURS_PAGE = 'tours'
}

export type GeoLoc = {
  lat: number;
  lng: number;
};

export type SearchDropdownAttraction = {
  city: string;
  country: string;
  name: string;
  objectID: string;
  rating: number;
  slug: string;
  _geoloc: GeoLoc;
};

export type SearchDropdownThingsTodo = {
  city: string;
  country: string;
  name: string;
  id: string;
  slug: string;
  thingsToDo: string;
};

export type TourCity = {
  objectID: string;
  name: string;
  country: string;
  id: string;
};

export interface CarouselImage {
  alt: string;
  image: string;
}

export interface CarouselVideo {
  alt: string;
  video: string;
}

export type CarouselItem = CarouselImage | CarouselVideo;

export interface TourCardType {
  id: string;
  city: string;
  country: string;
  avg_rating: number;
  carousel?: CarouselItem[];
  duration: number;
  highlight?: string;
  name: string;
  objectID: string;
  private?: boolean;
  reviews_count: number;
  slug: string;
  snippet?: string;
  price?: string;
}

export interface TourCardProps {
  tourCard: TourCardType;
  index: number;
}
