import { useRouter } from 'next/router';

// retaining search keyword whenever we change route on /tours and keep scrollY top to 20 avoid blinking repeatedly
export const useUpdateQueryParams = () => {
  const router = useRouter();

  const updateQueryParams = (
    newParams: Record<string, string | number>,
    path: string = '/'
  ) => {
    // Construct new query object with existing and new parameters
    const currentParams = { ...router.query };

    // Convert all newParams values to strings or arrays of strings
    const updatedParams: Record<string, string | string[]> = {};
    Object.keys(newParams).forEach(key => {
      const value = newParams[key];
      updatedParams[key] = Array.isArray(value)
        ? value.map(String)
        : String(value);
    });

    // Construct the new URL with updated query parameters
    const searchParams = new URLSearchParams(
      currentParams as Record<string, string>
    );
    Object.keys(updatedParams).forEach(key => {
      const value = updatedParams[key];
      if (Array.isArray(value)) {
        value.forEach(v => searchParams.append(key, v));
      } else {
        searchParams.set(key, value);
      }
    });

    // Use the provided path or the default path
    const updatedUrl = `${path}?${searchParams.toString()}`;

    // Replace the current route with the updated URL
    router.replace(updatedUrl, undefined, { shallow: true });
  };

  return updateQueryParams;
};
